.feedPageContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding: 84px 20px 60px;
    display: flex;
    flex-direction: column;
}

/* Responsive design */
@media (max-width: 600px) {
    .feedPageContainer {
        padding: 64px 12px 60px;
    }
}
